<template>
  <v-card v-if="contest" class="mt-4 card-contest-details">
    <v-card-text>
      <v-row class="contest-details">
        <v-col cols="12" sm="6" md="3" class="contest-details-block from-to">
          <div class="head text--primary">
            <v-icon>$vuetify.icons.calendar</v-icon>Срок приема работ
          </div>
          <div class="body">{{ contest.from_to }}</div>
        </v-col>
        <v-col cols="12" sm="6" md="3" class="contest-details-block date-result">
          <div class="head text--primary">
            <v-icon>$vuetify.icons.clock_alert_outline</v-icon>Оглашение результатов
          </div>
          <div class="body">{{ contest.text_date_result }}</div>
        </v-col>
        <v-col cols="12" sm="6" md="3" class="contest-details-block count_char">
          <div class="head text--primary">
            <v-icon>$vuetify.icons.format_letter_case</v-icon>Размер
          </div>
          <div class="body">{{ contest.text_count_char }}</div>
        </v-col>
        <v-col cols="12" sm="6" md="3" class="contest-details-block categories">
          <div class="head text--primary">
            <v-icon>$vuetify.icons.pen</v-icon>Жанры
          </div>
          <div class="body">
            <div
              v-for="(item, index) in categories"
              :key="item.id"
              :item="item"
              :index="index"
            >{{ item.text}}</div>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data: () => ({}),
  methods: {},
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      loadPage: "loadPage",
      books: "contest/books",
      contest: "contest/contest",
    }),
    categories() {
      if (!this.contest || !this.contest.categories_show) {
        return [];
      }
      let categories = this.contest.categories_show.map(function (category) {
        category.text = category.name;
        category.to = { name: "CategoryShow", params: { slug: category.slug } };
        return category;
      });

      return categories;
    },
  },
  created() {},
};
</script>
